body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  padding-top: 0.5rem;
  margin: 0 auto;
  width: 1200px;
}

div.note {
  font-family: Arial;
  font-size: 30px;
  font-weight: bold;
  border: solid 1px black;
  width: 38px;
  height: 38px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.note table {
  border-collapse: collapse;
}

div.note td {
  padding: 0;
}

div.note td.tone {
}

div.note td.index {
  font-size: 45%
}

div.note td.flatsharp {
  vertical-align: top;
}

div.note td.octave {
  vertical-align: bottom;
}

.hole {
  color:white;
  background-color: black;
  /*border: solid 1px white;*/
  border-radius: 10px;
  font-family: fantasy !important;
  font-weight: normal !important;
}

.blow {
  background-color: burlywood;
}

.base {
  background-color: navajowhite;
}

.draw {
  background-color: turquoise;
}

.blow-bend {
  background-color: plum;
}

.draw-bend {
  background-color: orange;
}

.overblow {
  background-color: lightcoral;
}

.overdraw {
  background-color: tomato;
}

td.caption {
  font-family: Arial;
  font-weight: bold;
  font-size: 26px;
  padding: 0 15px 0 15px;
  color: white;
}

td.align-left {
  text-align: left;
}

td.align-right {
  text-align: right;
}

button.note-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
}

button.note-toggle:focus {
  outline: 0;
}

button.note-toggle div.note {
  /*font-size: 20px;*/
  /*width: 27px;*/
  /*height: 27px;*/
  color: dodgerblue;
  border: solid 1px dodgerblue;
}

button.note-toggle-checked div.note {
  color:white;
  background-color: dodgerblue;
}
